import React from "react";
import "./statistic-chart.css";

const HorizontalBarChart = ({ label, value, count, type = "default" }) => {
  if (type === "split") {
    const complementValue = 100 - value;

    return (
      <div className="horizontal-bar-chart horizontal-bar-chart--split">
        <div className="horizontal-bar-chart__label-container">
          <span className="horizontal-bar-chart__percentage">
            {value || 0}%
          </span>
          <span className="horizontal-bar-chart__label">
            {label}
          </span>
          <span className="horizontal-bar-chart__percentage horizontal-bar-chart__percentage--right">
            {complementValue}%
          </span>
        </div>
        <div className="horizontal-bar-chart__bar-container">
          <div
            className="horizontal-bar-chart__bar-filled"
            style={{
              width: `${value}%`,
            }}
          ></div>
          <div
            className="horizontal-bar-chart__bar-empty"
            style={{
              width: `${complementValue}%`,
            }}
          ></div>
        </div>
      </div>
    );
  }

  // Default HorizontalBarChart
  return (
    <div className="horizontal-bar-chart">
      <div className="horizontal-bar-chart__label-container">
        <span className="horizontal-bar-chart__label">{label}</span>
        <span className="horizontal-bar-chart__percentage">
          {value}%{count ? ` (${count})` : ""}
        </span>
      </div>
      <div className="horizontal-bar-chart__bar-container">
        <div
          className="horizontal-bar-chart__bar-filled"
          style={{ width: `${value}%` }}
        ></div>
      </div>
    </div>
  );
};

const StatisticChart = ({ data = [], type = "default" }) => {
  return (
    <div className="statistic-chart">
      <div className="statistic-chart__bars">
        {data.map((item, index) => (
          <HorizontalBarChart
            key={index}
            label={item.label}
            value={item.value}
            count={item.count}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

export default StatisticChart;