import checkBorder from "./check-border.svg";
import radioChecked from "./radio-checked.svg";
import typeSelect from "./type-select.svg";
import date from "./date.svg";
import text from "./text.svg";

const AnalyticsImages = {
  checkBorder,
  radioChecked,
  typeSelect,
  date,
  text
};

export default AnalyticsImages;
