import "./info-card.css";
import { Progress } from "antd";

const InfoCard = ({ title, subtitle, isPercent = false }) => {
  return (
    <div className="info-card">
      <div className="info-card__title">
        {isPercent ? (
          <span
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Progress
              type="circle"
              percent={title}
              width={25}
              strokeLinecap="butt"
              strokeWidth={25}
              strokeColor="#2D7FFA"
              trailColor="rgba(45, 127, 250, 0.2)"
              format={() => ""}
            />
            {title}%
          </span>
        ) : (
          <span>{title}</span>
        )}
      </div>
      <p className="info-card__subtitle">{subtitle}</p>
    </div>
  );
};

export default InfoCard;
