import { Button, Dropdown } from "antd";
import { Icon } from "../icon/icon";

export const DrawerHeader = ({
  title,
  subtitle,
  isEditing,
  onEdit,
  onClose,
  onSave,
  onCancel,
  menu,
  isLoading,
  badge
}) => {
  return (
    <div className="drawer-header">
      <div className="drawer-header__left">
        <div className="drawer-header__titles">
          {badge && (
            <p className="drawer-header__badge">
              {typeof badge === "string" ? badge : badge}
            </p>
          )}
          {typeof title === "string" ? (
            <h2 className="drawer-header__title">{title}</h2>
          ) : (
            title
          )}
          {subtitle && (
            <p className="drawer-header__subtitle">
              {typeof subtitle === "string" ? subtitle : subtitle}
            </p>
          )}
        </div>
      </div>
      <div className="drawer-header__right">
        {isEditing ? (
          <>
            <Button
              size={"large"}
              onClick={onCancel}
              style={{ marginRight: 8 }}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              size={"large"}
              onClick={onSave}
              type={"primary"}
              loading={isLoading}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            {menu && (
              <Dropdown trigger="click" menu={menu}>
                <Button
                  type={"text"}
                  size={"middle"}
                  icon={<Icon name={"dots"} />}
                />
              </Dropdown>
            )}
            {onEdit && (
              <Button
                type={"text"}
                size={"middle"}
                onClick={onEdit}
                icon={<Icon name={"edit"} />}
              />
            )}
            {onClose && (
              <Button
                size={"middle"}
                type={"text"}
                onClick={onClose}
                icon={<Icon name={"close"} />}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
