import PropTypes from 'prop-types';

const Question = ({ text, type = "default", width = "60%" }) => {
  return (
    <div
      className={`question-container question-text-container ${type === "ghost" ? "question-container-ghost" : ""}`}
      style={{ width, marginTop: "10px" }}
    >
      <p className={`question ${type === "ghost" ? "question-ghost" : ""}`}>{text}</p>
    </div>
  );
};

Question.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.string
};

export default Question;
