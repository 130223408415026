import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearEngagement,
  fetchEngagement,
  selecthasMoreEngagement,
  selectEngagement,
  selectEngagementLoading,
  selectAuthors
} from "../../../../store/slices/article-engagements";
import AuditEngagementHeader from "../audit-engagement-header";
import "../../styles.css";
import InfinityTable from "../../../../components/infinity-table/infinity-table";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
import { Body } from "../../../../components/main-body/body";
import classNames from "classnames";
import "../../../../components/main-body/body.css";
import { Progress } from "antd";
import ApiCategoryManagement from "../../../../api/category-managements";
import ApiArticles from "../../../../api/articles";
import ArticleEngagementCard from "./article-engagement-card/article-engagement-card";
import { useNavigate, useLocation } from "react-router-dom";
import ApiSubjectsDashboard from "../../../../api/subjects-dashboard";

const ArticleEngagementTable = ({ navigation }) => {
  const [subjectsList, setSubjectsList] = useState([]);
  const engagements = useSelector(selectEngagement);
  const authors = useSelector(selectAuthors);
  const hasMore = useSelector(selecthasMoreEngagement);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const isLoading = useSelector(selectEngagementLoading);
  const [filters, setFilters] = useState({});
  const [categoriesList, setCategoriesList] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [selectedEngagement, setSelectedEngagement] = useState({});
  const [cohortsList, setCohortsList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const getDashboardFilterItems = () => {
    ApiSubjectsDashboard.filterField().then((r) => {
      setCohortsList(r.data.cohorts?.map((c) => ({ id: c.id, name: c.value })));
    });
  };

  const handleSelectEngagement = (page) => {
    handleNavigate(`/reports/engagement/articles/${page.id}`);
    setSelectedEngagement(page);
    setOpen(true);
  };

  const fetchSubjectList = () => {
    ApiSubjectsManagement.getSubjectsSimple().then((r) => {
      setSubjectsList(
        r.data.subjects.map((s) => ({
          id: s.id,
          name: s.displayId,
        }))
      );
    });
  };

  useEffect(() => {
    fetchSubjectList();
    getDashboardFilterItems();
  }, []);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const articleId = pathParts[pathParts.length - 1];

    if (articleId && articleId !== "articles") {
      setSelectedEngagement({ id: articleId });
      setOpen(true);
    }
  }, [location.pathname]);

  const getTags = useCallback(async () => {
    const response = await ApiArticles.getTags();
    if (response.data && response.data.tags) {
      setTags(response.data.tags);
    }
  }, [ApiArticles]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const closeTaskDrawer = () => {
    handleNavigate(`/reports/engagement/articles`);
    setOpen(false);
    setSelectedEngagement({});
  };

  const fetchData = (currentPage = page, query = searchValue, currentFilters = filters, reset = false) => {
    if (reset) {
      dispatch(clearEngagement()); // Очищення даних лише під час пошуку або фільтрації
    }

    dispatch(
      fetchEngagement({
        query,
        page: currentPage,
        ...currentFilters,
        createdAtEnd: currentFilters.period?.[currentFilters.period.length - 1]?.end,
        createdAtStart: currentFilters.period?.[currentFilters.period.length - 1]?.start,
      })
    );
    setPage(currentPage + 1);
  };

  const handleUpdateSearchValue = (value) => {
    setSearchValue(value);
    setPage(1);
    fetchData(1, value, filters, true);
  };

  const fetchCategoryList = () => {
    ApiCategoryManagement.getCategories().then((r) => {
      setCategoriesList(
        r.data.categories.map((s) => ({
          id: s.id,
          name: s.name,
        }))
      );
    });
  };

  const updateFilterValue = (filter) => {
    setFilters(filter);
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  useEffect(() => {
    dispatch(clearEngagement());
    fetchData(1);
  }, [filters, dispatch]);

  const columns = [
    {
      title: t("engagement.table.title"),
      dataIndex: "title",
      render: (_, engagement) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>
            <span style={{ fontWeight: "bold" }}>{engagement.title || "N/A"}</span>
            <div style={{ fontSize: "12px", color: "#888" }}>
              {engagement?.Category?.name}
            </div>
          </div>
        </div>
      ),
      key: "title",
      width: "40%"
    },
    {
      title: t("engagement.table.likes"),
      dataIndex: "likes",
      render: (_, engagement) => <span>{engagement.likesCount || 0}</span>,
      key: "likes",
      width: "15%"
    },
    {
      title: t("engagement.table.dislikes"),
      dataIndex: "dislikes",
      render: (_, engagement) => <span>{engagement.dislikesCount || 0}</span>,
      key: "dislikes",
      width: "15%"
    },
    {
      title: t("engagement.table.sentiment_ratio"),
      dataIndex: "sentimentRatio",
      render: (_, engagement) => {
        const hasData =
          engagement.likesPercent > 0 || engagement.dislikesPercent > 0;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {hasData ? (
              <>
                <span style={{ marginRight: 8, width: 40, textAlign: "right" }}>{engagement.likesPercent}%</span>
                <Progress
                  size="small"
                  showInfo={false}
                  percent={engagement.likesPercent}
                  strokeColor="#2D7FFA"
                  style={{ flex: 1, marginInlineEnd: 0, marginBottom: 2 }}
                />
                <span style={{ marginLeft: 8, width: 40, textAlign: "left" }}>{engagement.dislikesPercent}%</span>
              </>
            ) : (
              <span>N/A</span>
            )}
          </div>
        );
      },
      key: "sentimentRatio",
      width: "30%"
    },
  ];
  const filterItems = [
    { name: "Cohorts", id: "cohortIds", children: cohortsList },
    { name: "Date peroid", id: "period", period: true },
    { name: "Tags", id: "tags", children: tags },
    { name: "Category", id: "categoryIds", children: categoriesList },
    { 
      name: "Author", 
      id: "userIds", 
      children: authors.map(x => ({ id: x.id, name: x.fullName })) 
    }
  ];

  const headerProps = useMemo(
    () => ({
      search: {
        value: searchValue,
        onChange: handleUpdateSearchValue,
        placeholder: t("assets.btn.search")
      }
    }),
    [searchValue]
  );

  return (
    <Body disabledPaddings={true}>
      <AuditEngagementHeader
        t={t}
        filterItems={filterItems}
        updateFilterValue={updateFilterValue}
        header={headerProps}
      />
      <div className={classNames("lf-content")}>
        <InfinityTable
          data={engagements}
          fetchData={() => fetchData(page, searchValue, filters)}
          hasMore={hasMore}
          tableColumns={columns}
          isLoading={isLoading}
          before={navigation}
          onHandleRowClick={handleSelectEngagement}
        />
      </div>
      <ArticleEngagementCard
        engagement={selectedEngagement}
        isOpen={isOpen}
        close={closeTaskDrawer}
        subjects={subjectsList}
        cohorts={cohortsList}
        initialPeriodFilter={filters.hasOwnProperty('period') ? { period: filters.period } : {} }
        initialCohortsFilter={filters.hasOwnProperty('cohortIds') ? { cohortIds: filters.cohortIds } : [] }
      />
    </Body>
  );
};

export default ArticleEngagementTable;
