import Answer from "./answer";
import Question from "./question";

const BehaviorRulesSelector = ({ data, setAnswer, answeredLabel }) => {
  return (
    <div className="multiple-choice">
      <Question width="85%" type="ghost" text={"Select branch to simulate behavior condition..."} />
      <div className="multiple-choice-answers">
        {data?.conditions?.map((item, idx) => {
          const isLast = idx === data.conditions.length - 1;
          let label = `Branch ${idx + 1}`;
          if (isLast) {
            label = 'Else';
          }
          return (
            <Answer
              key={label}
              text={label}
              locked={answeredLabel !== undefined && idx !== answeredLabel}
              selected={answeredLabel !== undefined && idx === answeredLabel}
              onClick={() => setAnswer(idx)}
            />
          )}
        )}
      </div>
    </div>
  );
};

export default BehaviorRulesSelector;
