import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const getAricles = (query) => axios.get(toQueryString("api/v1/engagements/pages?", query));

const getEngagementById = (id, query) => axios.get(toQueryString(`api/v1/engagements/pages/${id}?`, query));


const ApiEngagement = {
  getAricles,
  getEngagementById
};

export default ApiEngagement;
