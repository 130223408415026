import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const getAnalytics = (query) => axios.get(toQueryString("api/v1/analytics/scenarios?", query));

const getAnalyticsById = (id, query) => axios.get(toQueryString(`api/v1/analytics/scenarios/${id}?`, query));

const ApiAnalytics = {
  getAnalytics,
  getAnalyticsById
};

export default ApiAnalytics;
