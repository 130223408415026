import { v4 as uuidv4 } from "uuid";

function replaceScenarioPickerNodes(mainScenario, embeddedScenarios) {
  const result = JSON.parse(JSON.stringify(mainScenario));
  const updatedEmbeddedScenarios = JSON.parse(JSON.stringify(embeddedScenarios));

  for (const [nodeId, node] of Object.entries(result)) {
    if (node.data.nodeType === 'scenario:picker') {
      const embeddedScenario = embeddedScenarios[nodeId];
      if (embeddedScenario) {
        // Remove the scenario picker node
        delete result[nodeId];

        const prevNodeId = node.prevNodes[0];
        const nextNodeId = node.nextNodes[0];

        const embeddedStartNode = Object.values(embeddedScenario).find(node => node.type === 'StartNode');
        const embeddedFinishNode = Object.values(embeddedScenario).find(node => node.type === 'FinishNode');

        // Create a mapping of old IDs to new UUIDs
        const idMapping = {};

        // First pass: Create new IDs for all nodes
        for (const [embeddedNodeId, embeddedNode] of Object.entries(embeddedScenario)) {
          if (embeddedNode.type !== 'StartNode' && embeddedNode.type !== 'FinishNode') {
            const newId = uuidv4();
            idMapping[embeddedNodeId] = newId;
          }
        }

        // Second pass: Add nodes to result and update links
        for (const [embeddedNodeId, embeddedNode] of Object.entries(embeddedScenario)) {
          if (embeddedNode.type !== 'StartNode' && embeddedNode.type !== 'FinishNode') {
            const newId = idMapping[embeddedNodeId];
            const newNode = { ...embeddedNode, id: newId };

            const restBranchChunkId = newNode.branchId.split('-').slice(1).join('-');
            newNode.branchId = `${node.branchId}${restBranchChunkId ? `-${restBranchChunkId}` : ''}`;
            newNode.prevNodes = embeddedNode.prevNodes.map(id => {
              if (id === embeddedStartNode.id) return prevNodeId;
              return idMapping[id] || id;
            });

            newNode.nextNodes = embeddedNode.nextNodes.map(id => {
              if (id === embeddedFinishNode.id) return nextNodeId;
              return idMapping[id] || id;
            });
            newNode.internalId = embeddedNodeId;

            result[newId] = newNode;

            if (newNode.data.nodeType === 'scenario:picker') {
              updatedEmbeddedScenarios[newId] = updatedEmbeddedScenarios[embeddedNodeId];
            }
          }
        }

        // Update the links in the main scenario
        result[prevNodeId].nextNodes = embeddedStartNode.nextNodes.map(id => idMapping[id] || id);
        result[nextNodeId].prevNodes = embeddedFinishNode.prevNodes.map(id => idMapping[id] || id);
      }
    }
  }

  return {result, updatedEmbeddedScenarios};
}

export function assembleWithEmbeddedScenarios(scenario, embeddedScenarios) {
  let embedIterationsCounter = 0;

  let result, updatedEmbeddedScenarios;
  let assembleResult = replaceScenarioPickerNodes(scenario, embeddedScenarios);
  result = assembleResult.result;
  updatedEmbeddedScenarios = assembleResult.updatedEmbeddedScenarios;

  while (embedIterationsCounter < 10 && Object.values(result).find(node => node.data.nodeType === 'scenario:picker')) {
    assembleResult = replaceScenarioPickerNodes(result, updatedEmbeddedScenarios);
    result = assembleResult.result;
    updatedEmbeddedScenarios = assembleResult.updatedEmbeddedScenarios;
    embedIterationsCounter++;
  }

  return assembleResult;
}
