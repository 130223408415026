import "./styles.css";
import { Outlet, useLocation } from "react-router-dom";
import { memo } from "react";
import { MainLayoutV2 } from "../../components/main-layout-v2/main-layout-v2";
import { MainNavigation } from "../../components/main-navigation/main-navigation";
import { Sidebar } from "../../components/sidebar/sidebar";

const Reports = memo(() => {
  const { pathname } = useLocation();

  const navigation = [
    {
      label: "Responses",
      type: "group",
      children: [
        {
          key: "/reports/analytics/scenarios",
          label: "Scenarios",
        },
        {
          key: "/reports/analytics/tasks",
          label: "Tasks",
        },
        {
          key: "/reports/analytics/onboarding",
          label: "Onboarding",
        }
      ],
    },
    {
      label: "Engagement",
      type: "group",
      children: [
        {
          key: "/reports/engagement/articles",
          label: "Articles",
        }
      ],
    },
    {
      label: "Audit trail",
      type: "group",
      children: [
        {
          key: "/reports/audit-trail/subjects",
          label: "Subjects",
          default: pathname === "/reports" || pathname === "/reports/audit-trail/subjects",
        },
        { key: "/reports/audit-trail/scenarios", label: "Scenarios" },
        { key: "/reports/audit-trail/users", label: "Users" },
      ],
    },
  ];

  return (
    <MainLayoutV2>
      <MainNavigation />
      <Sidebar
        title={"Reports"}
        items={navigation}
      />
      <Outlet />
    </MainLayoutV2>
  );
});

export default Reports;
