import { DrawerHeader } from "../../../../../components/drawer/drawer-header";
import { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Drawer } from "../../../../../components/drawer/drawer";
import InfinityTable from "../../../../../components/infinity-table/infinity-table";
import ApiAnalytics from "../../../../../api/analytics";
import InfoCardWrapper from "../../../../../components/info-card/info-card-wrapper";
import StatisticChart from "../../../../../components/statistic-chart/statistic-chart";
import DropdownFilters from "../../../../../components/dropdown-filters/dropdown-filters";
import { Tabs } from "antd";
const { TabPane } = Tabs;

const AnalyticsCardInside = memo(
  ({
    analytics,
    close,
    messageTypes,
    subjects,
    cohorts,
    initialPeriodFilter,
    initialCohortsFilter
  }) => {
    const [filters, setFilters] = useState({
      cohortIds: initialCohortsFilter?.cohortIds || null,
      period: initialPeriodFilter || null,
      subjectIds: null,
    });
    const filterItems = [
      { name: "Date peroid", id: "period", period: true },
      { name: "Subject ID", id: "subjectIds", children: subjects },
      { name: "Cohorts", id: "cohortIds", children: cohorts },
    ];
    const [localAnalytics, setLocalAnalytics] = useState(analytics); 
    const { t } = useTranslation();

    const fetchFilteredAnalytics = (currentFilters) => {
      if (Array.isArray(currentFilters.period)) {
        currentFilters = {
          ...currentFilters,
          createdAtEnd: currentFilters.period?.[currentFilters.period.length - 1]?.end,
          createdAtStart: currentFilters.period?.[currentFilters.period.length - 1]?.start,
        };
      }
      ApiAnalytics.getAnalyticsById(analytics.id, currentFilters).then((response) => {
        const updatedAnalytics = {
          ...analytics,
          ...response.data.question,
          SubjectScenarioResponses: response.data.question.SubjectScenarioResponses || [],
        };
        setLocalAnalytics(updatedAnalytics);
      });
    };

    useEffect(() => {
      if (initialPeriodFilter) {
        if (filters.period !== initialPeriodFilter) {
          const updatedFilters = { ...filters, period: initialPeriodFilter };
          setFilters(updatedFilters);
          fetchFilteredAnalytics(updatedFilters);
        }
      }
    }, [initialPeriodFilter]); 

    const transformAnalyticsToData = (analytics) => {
      return [
        {
          title: analytics.respondedPercentage || 0,
          subtitle: t("analytics.card.adherence_rate"),
          isPercent: true,
        },
        {
          title: analytics.respondedCount || 0,
          subtitle: t("analytics.card.responded"),
          isPercent: false,
        },
        {
          title: analytics.pendingCount || 0,
          subtitle: t("analytics.card.pending"),
          isPercent: false,
        },
        {
          title: analytics.noRespondedCount || 0,
          subtitle: t("analytics.card.no_response"),
          isPercent: false,
        }
      ];
    };

    const data = transformAnalyticsToData(localAnalytics);

    const copyId = () => {
      navigator.clipboard.writeText(localAnalytics.internalId)
    };

    const getMessageNameById = (id) => {
      const message = messageTypes.find((type) => type.id === id);
      return message ? message.name : null;
    };

    const updateFilterValue = (filter) => {
      setFilters(filter);
    };

    useEffect(() => {
      fetchFilteredAnalytics(filters);
    }, [filters]);

    const columnsResponded = [
      {
        title: t("analytics.table.subjectId"),
        dataIndex: "subjectId",
        key: "subjectId",
        render: (_, analytics) => <span>{analytics.Subject.displayId}</span>,
        width: "20%",
      },
      {
        title: t("analytics.table.response"),
        dataIndex: "response",
        key: "response",
        render: (_, analytics) => <span>{analytics.answer}</span>,
        width: "40%",
      },
      {
        title: t("analytics.table.completion_date"),
        dataIndex: "completion_date",
        key: "completion_date",
        render: (_, analytics) =>
          analytics.completedAt
            ? `${moment(analytics.completedAt).utc().format("DD MMM YYYY, HH:mm")}, UTC`
            : "N/A",
        width: "40%",
      },
    ];

    const columnsPendingAndNoResponded = [
      {
        title: t("analytics.table.subjectId"),
        dataIndex: "subjectId",
        key: "subjectId",
        render: (_, analytics) => <span>{analytics.Subject.displayId}</span>,
        width: "50%",
      },
      {
        title: t("analytics.table.date"),
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_, analytics) =>
          analytics.createdAt
            ? `${moment(analytics.createdAt).utc().format("DD MMM YYYY, HH:mm")}, UTC`
            : "N/A",
        width: "50%",
      },
    ];
    return (
      <>
        <DrawerHeader
          badge={localAnalytics?.Scenario?.title}
          title={localAnalytics.title}
          subtitle={getMessageNameById(localAnalytics.questionType)}
          onClose={close}
          menu={
            {
              items: [
                {
                  label: "Copy ID",
                  onClick: copyId
                }
              ]
            }
          }
        />
        <div style={{ paddingBottom: 12 }} />
        <DropdownFilters
          filtersBy={filterItems}
          updateFilterValue={updateFilterValue}
          filter={{ ...initialPeriodFilter, ...initialCohortsFilter }}
        />
        <div style={{ paddingBottom: 12 }} />
        <InfoCardWrapper data={data} />
        <div style={{ paddingBottom: 12 }} />
        {localAnalytics.responseStats && (
          <StatisticChart 
            data={localAnalytics.responseStats}
          />
        )}
        <div style={{ paddingBottom: 12 }} />
        <div className="analytics-tabs">
          <Tabs defaultActiveKey="1">
            <TabPane tab={t("analytics.card.responded")} key="1">
              <InfinityTable data={localAnalytics.responded} tableColumns={columnsResponded} />
            </TabPane>
            <TabPane tab={t("analytics.card.pending")} key="2">
              <InfinityTable data={localAnalytics.pending} tableColumns={columnsPendingAndNoResponded} />
            </TabPane>
            <TabPane tab={t("analytics.card.no_response")} key="3">
              <InfinityTable data={localAnalytics.noResponded} tableColumns={columnsPendingAndNoResponded} />
            </TabPane>
          </Tabs>
        </div>
      </>
    );
  }
);

const AnalyticsCard = ({
  analytics,
  isOpen,
  close,
  copy,
  messageTypes,
  subjects,
  cohorts,
  initialPeriodFilter,
  initialCohortsFilter
}) => {
  return (
    <Drawer open={isOpen} width={800} closable={false} onClose={close}>
      <div style={{ paddingBottom: 48 }}>
        {isOpen && (
          <AnalyticsCardInside
            analytics={analytics}
            close={close}
            messageTypes={messageTypes}
            subjects={subjects}
            cohorts={cohorts}
            initialPeriodFilter={initialPeriodFilter}
            initialCohortsFilter={initialCohortsFilter}
          />
        )}
      </div>
    </Drawer>
  );
};

export default AnalyticsCard;
