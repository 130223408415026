import { createSlice } from "@reduxjs/toolkit";
import ApiEngagement from "../../api/engagement";

export const AuditEngagementSlice = createSlice({
  name: "article-engagements",
  initialState: {
    engagements: [],
    hasMore: false,
    isLoading: false,
    authors: []
  },
  reducers: {
    setEngagement: (state, action) => {
      console.log('setEngagement', action.payload)
      const newEngagement = action.payload.filter(
        (newItem) => !state.engagements.some((existingItem) => existingItem.id === newItem.id)
      );
      return {
        ...state,
        engagements: state.engagements ? [...state.engagements, ...newEngagement] : newEngagement,
        hasMore: action.payload?.length && action.payload?.length > 28,
        isLoading: false
      };
    },
    setAuthors: (state, action) => {
      console.log('setAuthors', action.payload)
      return {
        ...state,
        authors: action.payload
      };
    },
    resetEngagement: (state) => ({
      ...state,
      engagements: [],
      hasMore: false,
      isLoading: false,
    }),
    setLoading: (state) => ({ ...state, isLoading: true }),
  },
});

export const { setEngagement, resetEngagement, setLoading, setAuthors } = AuditEngagementSlice.actions;

export const fetchEngagement = (query) => (dispatch) => {
  dispatch(setLoading());
  ApiEngagement.getAricles(query)
    .then((r) => {
      dispatch(setEngagement(r.data.engagements));
      dispatch(setAuthors(r.data.authors));
    })
    .catch(() => {
      dispatch(resetEngagement());
    });
};

export const clearEngagement = () => (dispatch) => {
  dispatch(resetEngagement());
};

export const selectAuthors = (state) => state.articleEngagements.authors;
export const selectEngagement = (state) => state.articleEngagements.engagements;
export const selecthasMoreEngagement = (state) => state.articleEngagements.hasMore;
export const selectEngagementLoading = (state) => state.articleEngagements.isLoading;

export default AuditEngagementSlice.reducer;
