import { Form } from "antd";
import {
  FormFieldName,
  FormHeaderTextarea,
  FormSelect,
  FormSelectWithSearch,
} from "../../../../components/form/form";
import TagsSelect from "../../../../components/tags-select/tags-select";
import { DrawerHeader } from "../../../../components/drawer/drawer-header";
import { useEffect, useMemo, useState } from "react";
import { Drawer } from "../../../../components/drawer/drawer";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/slices/user";
import SimpleColorPicker from "../../../../components/color-picker/color-picker";
import "../life-facts.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LifeFactsModal = ({
  form,
  data,
  onClose,
  onSave,
  authors,
  categories,
  articleId,
  factCitations,
  factUrls,
  tags,
  isLoading,
  mode
}) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const currentUser = useSelector(selectUser);
  const navigate = useNavigate();
  const urlPart = "/administration/fitfacts";
  useEffect(() => {
    if (mode === "edit") {
      setEditMode(true);
    }

    if (mode === "create") {
      setEditMode(true);
    }

    if (mode === "show") {
      setEditMode(false);
    }
  }, [mode]);

  const closeHandler = () => {
    navigate(urlPart);
    setEditMode(false);
    onClose();
  };

  const editHandler = () => {
    navigate(`${urlPart}/${articleId}/edit`);
    setEditMode(true);
  };

  const saveHandler = () => {
    form.setFieldValue("status", null);
    onSave();

    const formTags = form.getFieldValue("tags");
    const newTags = formTags.filter((tag) => !tags.some((t) => t.id === tag.id));

    if (newTags.length) {
      const updatedTags = [...tags, ...newTags];
      form.setFieldValue("tags", updatedTags);
    }

    setEditMode(false);
    if (articleId) {
      navigate(`${urlPart}/${articleId}`);
    } else {
      navigate(`${urlPart}`);
    }
  };

  const cancelHandler = () => {
    if (articleId) {
      navigate(`${urlPart}/${articleId}`);
    } else {
      navigate(`${urlPart}`);
    }
    setEditMode(false);
    if (data.status === "draft") {
      onClose();
    }
  };

  const updateSource = (value) => {
    form.setFieldValue("FactUrl", value);
  };

  const updateCitation = (value) => {
    form.setFieldValue("FactCitation", value);
  };

  const updateColor = (color) => {
    form.setFieldValue("color", color?.backgroundColor);
  };

  const updateAuthor = (author) => {
    form.setFieldValue(
      "userId",
      authors.find((a) => a.fullName === author)?.id
    );
  };

  const updateTags = (values) => {
    form.setFieldValue("tags", values);
  };

  const updateCategory = (category) => {
    form.setFieldValue("categoryId", category);
  };

  useEffect(() => {
    if (data?.status === "draft" && !data.text) {
      setEditMode(true);
    }
  }, [data]);

  const drawerContent = useMemo(() => {
    return data ? (
      <Form form={form}>
        <DrawerHeader
          title={<p className="life-fact-title">{t("lifefacts.title")}</p>}
          onEdit={editHandler}
          onSave={saveHandler}
          onCancel={cancelHandler}
          onClose={closeHandler}
          isEditing={editMode}
          isLoading={isLoading}
        />
        <div>
          <Form.Item name={"text"} initialValue={data.text}>
            <FormHeaderTextarea
              readonly={!editMode}
              maxLength={2000}
              placeholder={t("lifefacts.placeholder.enter_fitfact")}
            />
          </Form.Item>
        </div>
        <Form.Item name={"FactUrl"} initialValue={data.FactUrl}>
          <FormFieldName text={t("lifefacts.fields_name.source_url")} />
          <div style={{ marginTop: -4, width: "100%" }}>
            <FormSelectWithSearch
              canAddNew
              isURL
              multiline
              onSelect={updateSource}
              options={factUrls.map((f) => ({
                id: f.id,
                value: f.source?.trim(),
                subLabel: f.title?.trim(),
              }))}
              defaultValue={
                data.FactUrl && {
                  id: data?.FactUrl?.id,
                  value: data?.FactUrl?.source?.trim(),
                  subLabel: data?.FactUrl?.title?.trim(),
                }
              }
              readonly={!editMode}
              placeholder={
                editMode && t("lifefacts.placeholder.enter_source_url")
              }
            />
          </div>
        </Form.Item>
        <Form.Item name={"FactCitation"} initialValue={data.FactCitation}>
          <FormFieldName text={t("lifefacts.fields_name.source_citation")} />
          <div style={{ marginTop: -4, width: "100%" }}>
            <FormSelectWithSearch
              canAddNew
              multiline
              addBtnText={t("lifefacts.btnText.add_citation")}
              onSelect={updateCitation}
              placeholder={
                editMode && t("lifefacts.placeholder.enter_citation")
              }
              options={factCitations.map((f) => ({
                id: f.id,
                value: f.source,
              }))}
              defaultValue={
                data.FactCitation && {
                  id: data.FactCitation.id,
                  value: data.FactCitation.source,
                }
              }
              readonly={!editMode}
            />
          </div>
        </Form.Item>
        <Form.Item name={"categoryId"} initialValue={data.category}>
          <FormFieldName text="Category:" />
          <FormSelect
            options={categories.map((c) => ({
              value: c.id,
              label: c.name,
              key: c.id,
            }))}
            defaultValue={categories
              .map((cat) => ({ value: cat.id, key: cat.id, label: cat.name }))
              .find((c) => c.value === data.categoryId)}
            onSelect={updateCategory}
            placeholder={editMode && "Select category..."}
            style={{ minWidth: 160 }}
            bordered={false}
            readonly={!editMode}
          />
        </Form.Item>
        <Form.Item name={"tags"} initialValue={data.tags}>
          <FormFieldName text="Tags:" />
          <div style={{ marginLeft: 11 }}>
            <TagsSelect
              options={tags}
              defaultValue={data.tags}
              onChange={updateTags}
              onCreate={updateTags}
              readonly={!editMode}
              placeholder="+ Add Tag"
              noMarginLeft={true}
              isEditing={!editMode}
            />
          </div>
        </Form.Item>
        <Form.Item name={"color"} initialValue={data.color}>
          <FormFieldName text="Color:" />
          <div
            style={{
              marginLeft: 4,
              marginTop: -12,
            }}
          >
            <SimpleColorPicker
              onChange={updateColor}
              defaultValue={data.color}
              readonly={!editMode}
              opened={editMode}
            />
          </div>
        </Form.Item>
        <Form.Item
          name={"userId"}
          initialValue={authors.find((a) => a.id === data.userId)?.id}
        >
          <FormFieldName text="Author:" />
          <FormSelect
            placeholder={editMode && "Select author"}
            options={authors?.map((author) => ({
              id: author.id,
              value: author.fullName,
            }))}
            defaultValue={() => {
              const usr =
                authors.find((a) => a.id === data.userId) || currentUser;
              return usr ? { id: usr?.id, value: usr?.fullName } : null;
            }}
            onSelect={updateAuthor}
            readonly={!editMode}
          />
        </Form.Item>
      </Form>
    ) : null;
  }, [data, editMode]);
  return (
    <Drawer open={!!data} onClose={closeHandler} destoyOnClose={true}>
      {drawerContent}
    </Drawer>
  );
};

export default LifeFactsModal;
